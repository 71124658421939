import mutations from './mutations';
import getters from './getters';

const notification = {
  namespaced: true,
  state: {
    info: [],
    latestNotificationsCount: 0,
  },
  mutations,
  getters,
};

export default notification;
